var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};
var site = site || {};
var presentAfterpay = presentAfterpay || {};
var presentClearpay = presentClearpay || {};

(function ($) {
  Drupal.behaviors.skuList = {
    attach: function (context) {
      var $products = $('.js-product', context);
      var $accordionPanel = $('.js-accordion__panel', context);
      var $accordionTitle = $('.js-accordion__title', context);
      var $productQuickshop = $('.js-quickshop__sku', context);

      $('.js-product-price-installment', $accordionPanel).hide();
      $('.js-product-price-installment', $accordionTitle).hide();
      $('.js-product-price-installment', $productQuickshop).hide();

      $products.each(function () {
        var $product = $(this);
        var $skuList = $('.js-sku-list', $product);
        var $skuListItem = $('.js-sku-list__item', $skuList);
        var $item = $('.js-sku-list__button', $skuList);
        var $productImage = $('.js-product-image--default', $product);

        if ($skuList.length) {

          // var skuBaseId = $product.data('sku-base-id');
          // $(this).trigger('product.skuList', skuBaseId);
          $item.once().click(function (event) {
            event.preventDefault();
            var skuBaseId = $(this).data('sku-base-id');

            $product.trigger('product.skuSelect', skuBaseId);
            var amount = $('.js-sku-price').html()?.replace(/[^\d]/g, '');

            if (amount !== null && typeof presentAfterpay.afterpayConfig === 'function') {
              presentAfterpay.afterpayConfig(amount);
            }
            if (Drupal.settings.common.clearpay_unit_price) {
              var amountClearpay = $('.js-sku-unit-price').html().replace(/[^\d]/g, '');

              if (amountClearpay !== null && typeof presentClearpay.clearpayConfig === 'function') {
                presentClearpay.clearpayConfig(amountClearpay);
              }
            }
          });
        }
        if ($product.hasClass('product-brief--show-shade-size')) {
          if ($product.parents('.product-grid--small-cols-2').length > 0 && $skuListItem.length > 2 && site.client.isMobile) {
            $skuList.find('.js-sku-lists').not('.slick-initialized').slick({
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: false,
              arrows: true,
              variableWidth: true
            });
          } else if ($skuListItem.length > 4) {
            $skuList.find('.js-sku-lists').not('.slick-initialized').slick({
              slidesToShow: 4.2,
              slidesToScroll: 1,
              infinite: false,
              arrows: true,
              variableWidth: true,
              responsive: [
                {
                  breakpoint: 1250,
                  settings: {
                    slidesToShow: 3.5,
                    variableWidth: true
                  }
                },
                {
                  breakpoint: 980,
                  settings: {
                    slidesToShow: 2.5,
                    variableWidth: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 4.2
                  }
                }
              ]
            });
          }
          $product.trigger('product.skuSelect', $skuList.data('sku-base-id'));
          $item.hover(function () {
            var skuBaseId = $(this).data('sku-base-id');
            var skuData = prodcat.data.getSku(skuBaseId);

            $productImage.attr('src', skuData.IMAGE_MEDIUM[0]);
          }, function () {
            var skuBaseId = $('.js-sku-list__button.button--active', $skuList).data('sku-base-id');
            var skuData = prodcat.data.getSku(skuBaseId);

            $productImage.attr('src', skuData.IMAGE_MEDIUM[0]);
          });
        }
      });
    }
  };

  $(document).on(
    'product.quickshop.launch.overlay product.quickshop.launch.inline product.size.shade.init',
    function (event, newContext) {
      Drupal.behaviors.skuList.attach(newContext, Drupal.settings);
    }
  );

  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect product.skuList', '.js-product', function (event, skuBaseId) {
    if (!$('.js-sku-list', this).length || skuBaseId === undefined) {
      return;
    }

    var $skuListItems = $('.js-sku-list__button', this);

    $skuListItems.removeClass('button--active');
    $skuListItems.filter('[data-sku-base-id=' + skuBaseId + ']').addClass('button--active');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (skuData !== null) {
      site.showEngraving(skuData);
    }

    // Replace global variable content with currently selected product info in v2 templates
    var $skuContent = $('.js-sku-list__button.button--active').html();
    var $skuTarget = $('.js-size-content');

    if ($($skuTarget)) {
      $($skuTarget).html($skuContent);
    }
    if ($(event.target).hasClass('product-brief--show-shade-size') && skuData !== null) {
      $('.js-product-price', this).html(skuData.formattedPrice);
      if (skuData.IMAGE_MEDIUM) {
        $('.js-product-image--default', this).attr('src', skuData.IMAGE_MEDIUM[0]);
      }
    }
  });
})(jQuery);
