var generic = generic || {};
var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };

Drupal.behaviors.siteHeaderFormatterV1 = (function ($) {
  var $html = $();
  var $body = $();
  var $template = $();
  var hideHeaderOnScroll = false;
  var $gnavHamburger = $();
  var $gnavHamburgerInput = $();
  var $gnavMainSectionWrapper = $();
  var $gnavMainSection = $();
  var $gnavMainSectionContent = $();
  var $gnavSectionWrappers = $();
  var $gnavMainSectionTrigger = $();
  var $mainSectionTriggerReset = $();
  var $triggerResetLabel = $();
  var $allSectionGroups = $();
  var $allSectionGroupContent = $();
  var $gnavLinks = $();
  var $gnavLinksLabel = $();
  // Calculate desktop for mobile menu stuff.
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
  var position = $(window).scrollTop();
  var behavior = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var self = this;
      $html = $('html');
      $body = $('body');
      $template = $('.js-site-header-formatter-v1', context);
      $gnavHamburger = $('.js-gnav-mobile-trigger', $template);
      $gnavHamburgerInput = $('#' + $gnavHamburger.attr('for'));
      $gnavMainSectionWrapper = $('.js-site-header-formatter__sections', $template);
      $accountSelect = $('.js-site-header-formatter__account-select', $template);
      $accountMenuList = $('.js-site-header-formatter__account-menu-list', $template);
      $gnavMainSection = $('.js-header-nav-section__section-v1', $gnavMainSectionWrapper);
      $gnavMainSectionContent = $('.js-header-nav-section__section-content', $gnavMainSection);
      $gnavSectionWrappers = $gnavMainSection.slice(0, 2);
      $gnavMainSectionTrigger = $('.js-header-nav-section__section-label', $gnavMainSection);
      $mainSectionTriggerReset = $('#gnav_section_trigger_reset', $template);
      $triggerResetLabel = $('.js-gnav-section-trigger-reset', $template);
      $closeTrigger = $('.js-gnav-util-close', $template);
      // These elements are in gnav_section_formatter_v1, we collapse them too when gnav is closed.
      $allSectionGroups = $('.js-gnav-section-formatter-v1', $template);
      $allSectionGroupContent = $('.js-gnav-section-formatter__content-wrapper', $allSectionGroups);
      // These elements are in gnav_links_v1.
      $gnavLinks = $gnavMainSectionWrapper.find('.js-gnav-links-trigger');
      $gnavLinksLabel = $gnavMainSectionWrapper.find('.js-gnav-links-header-label');
      hideHeaderOnScroll = $template.hasClass('hide-site-header-on-scroll');

      $mainSectionTriggerReset.change(function () {
        if (this.checked) {
          $html.toggleClass('active-utility-overlay', false);
        }
      });

      if ($.cookie('hide_header_offer_banner')) {
        $template.toggleClass('header-offers-banner-hidden', true);
      }

      function _updateNavStatus() {
        var scroll = $(window).scrollTop();
        var templateHeight = $template.outerHeight(true);

        // If the user scroll down, hide the Nav
        if (scroll <= position) {
          $body.toggleClass('site-header-formatter--hidden', false);
        } else if (scroll > 1) {
          $body.toggleClass('site-header-formatter--hidden', true);
          $body.toggleClass('site-header-formatter--sticky', true);
        }

        if (scroll <= templateHeight) {
          $body.toggleClass('site-header-formatter--sticky', false);
        }

        position = scroll;
      }

      // close the nav.
      function _closeGnav(keepActive) {
        self.close(keepActive);
      }

      function _gnavHeaderTrigger() {
        var timeout;

        // Calculate desktop for mobile menu stuff.
        if (isDesktop) {
          $gnavMainSectionTrigger
            .off('mouseover.siteHeader click.siteHeader touchstart.siteHeader')
            .on('click.siteHeader touchstart.siteHeader', function (e) {
              var $self = $(this);

              _gnavPcTrigger($self, e);
            })
            .on('mouseover.siteHeader', function (e) {
              var $self = $(this);

              // Delay the opening of the Gnav menu.
              timeout = setTimeout(function () {
                _gnavPcTrigger($self, e);
              }, 200);
            })
            .off('mouseleave')
            .on('mouseleave', function () {
              clearTimeout(timeout);
            });
        } else {
          $gnavMainSectionTrigger.off('click touchstart').on('click', function (e) {
            var $self = $(this);
            if ($self.parent().attr('aria-expanded') === 'true') {

              $self
                .parent()
                .find('.js-header-nav-section__section-content')
                .attr('aria-hidden', 'true');
              $self.parent().attr('aria-expanded', 'false');
            } else {
              $self.parent().attr('aria-expanded', 'true');
              $self
                .parent()
                .find('.js-header-nav-section__section-content')
                .attr('aria-hidden', 'false');
            }
            _gnavMobileTrigger($self, e);
            $gnavSectionWrappers.attr('aria-expanded', 'true');
            $gnavSectionWrappers.prev().prop('checked', true);
            $gnavSectionWrappers
              .find('.js-header-nav-section__section-content')
              .attr('aria-hidden', 'false');
            $gnavSectionWrappers.find('.js-gnav-section-formatter-v1').attr('aria-expanded', 'true');
          });
        }
      }

      function _gnavPcTrigger($self, e) {
        var isKeyEvent = e.type === 'enter' ? true : false;
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $content.find('.js-gnav-section-formatter-v1').first();
        var $subNavcontent = $subNav.find('.js-gnav-section-formatter__content-wrapper');
        var keepActive = $content.length ? true : false;

        if ($parent.attr('aria-expanded') === 'true') {
          return;
        }
        _closeGnav(keepActive);
        if ($content.length) {
          e.preventDefault();
          $input.prop('checked', true);
          $parent.attr('aria-expanded', 'true');
          $content.attr('aria-hidden', 'false');
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
          $subNav.attr('aria-expanded', 'true');
          $subNavcontent.attr('aria-hidden', 'false');

          if (isKeyEvent) {
            _restrictNavigation($content);
          }
          $(document).trigger('search:close');
        }
      }

      function _gnavMobileTrigger($self, e) {
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $parent.find('.js-gnav-section-formatter-v1');

        _closeGnav(true);
        if (!$content.length) {
          return;
        }

        e.preventDefault();
        $input.prop('checked', true);
        $html.toggleClass('active-gnav', true);
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $subNav.attr('aria-expanded', 'true');
        // $parent.attr('aria-expanded', 'true'); // TODO: Remove later if not needed
        $content.attr('aria-hidden', 'false');
        $(document).trigger('search:close');
      }

      // for retaining Tab Focus within Container given.
      function _restrictNavigation($element) {
        $element.off('keydown.restrictNavigation').on('keydown.restrictNavigation', function (e) {
          var $this = $(this);
          var key = e.keyCode ? e.keyCode : e.which;
          var $closeBtn = isDesktop ? $triggerResetLabel.first() : $gnavHamburger.first();
          var $tabbables = $this.find(':tabbable');
          var $first = $tabbables.filter(':first');
          var $last = $tabbables.filter(':last');
          var $target = $(e.target);

          // no  Tab
          if (key !== 9 || e.isDefaultPrevented()) {
            if (key === 27) {
              // Esc
              $closeBtn.trigger('enter');
            } else if (key === 35) {
              // End
              $last.focus();
            } else if (key === 36) {
              // Home
              $first.focus();
            }

            return;
          }

          if (($target[0] === $last[0] || $target[0] === this) && !e.shiftKey) {
            $first.focus();
            e.preventDefault();
          } else if (($target[0] === $first[0] || $target[0] === this) && e.shiftKey) {
            $last.focus();
            e.preventDefault();
          }
        });
      }

      function _toggleMobileMenu(e) {
        var isKeyEvent = e && e.type === 'enter' ? true : false;

        // If mobile content is open then reset radio button for PC.
        $mainSectionTriggerReset.prop('checked', true);
        $html.toggleClass('active-gnav');
        if ($html.hasClass('active-gnav')) {
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
          $gnavMainSectionTrigger.first().trigger('click');
          if (isKeyEvent) {
            _restrictNavigation($gnavMainSectionWrapper);
          }
        } else {
          _closeGnav();
          $gnavMainSection.last().attr('aria-expanded', 'false');
        }
      }

      function _initKeyEvents(event) {
        var key = event.keyCode ? event.keyCode : event.which;
        var targetEvent;

        if (key === 9) {
          // Tab
          targetEvent = $.extend({}, event, { type: 'tab' });

          return $(event.target).trigger(targetEvent);
        }

        if (key === 13) {
          // Enter
          targetEvent = $.extend({}, event, { type: 'enter' });

          return $(event.target).trigger(targetEvent);
        }
        if (key === 27) {
          // Esc
          $(document).trigger('check_reset_gnav_radio_buttom');
        }
      }

      $template.on('keydown', function (event) {
        _initKeyEvents(event);
      });

      // Subnav updates using js only.
      if (isDesktop) {
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', 'true');
      }

      if (hideHeaderOnScroll) {
        $(window)
          .off('scroll.gnavHeaderBlock')
          .on('scroll.gnavHeaderBlock', _.throttle(_updateNavStatus, 100));
        _updateNavStatus();
      }

      _gnavHeaderTrigger();

      Unison.on('change', function () {
        bp = Unison.fetch.now();
        isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
        _closeGnav();
        _gnavHeaderTrigger();
      });

      $triggerResetLabel
        .off('mouseover.siteHeader click.siteHeader')
        .on('click.siteHeader', function () {
          _closeGnav();
        })
        .on('mouseover.siteHeader', function (e) {
          var $self = $(this);
          _gnavPcTrigger($self, e);
        });

      $gnavHamburger.off('click').on('click', function () {
        _toggleMobileMenu();
      });

      $gnavLinksLabel.off('click').on('click', function () {
        var targetId = $(this).attr('for');
        var $input = $('#' + targetId);
        var isChecked = $input.is(':checked');

        $input.siblings('.gnav-links__content').attr('aria-expanded', !isChecked);
      });

      $closeTrigger.once().on('click', function (event) {
        event.preventDefault();
        $(this).parent().addClass('hidden');
      });

      $accountSelect.once().on('click', function (event) {
        event.preventDefault();
        $('.js-site-header-utility-signed-in').toggleClass('expanded');
      });

      // Close Nav dropdown when moving the mouse out or clicking outside of it:
      $html.on('click', function (event) {
        if (
          isDesktop &&
          $html.hasClass('active-gnav') &&
          !$(event.target).parents('.site-header-formatter__header').length
        ) {
          _closeGnav();
        }
      });

      $(document).on('hide_header_offer_banner', function () {
        $template.toggleClass('header-offers-banner-hidden', true);
      });

      $(document).on('check_reset_gnav_radio_buttom', function () {
        // Reset Header Nav Section elements state.
        $gnavMainSection.attr('aria-expanded', 'false');
        $mainSectionTriggerReset.prop('checked', true);
      });

      // Build account menu items to be displayed in gnav
      self.accountMenuList(context);

      // If user has logged in, then show account related menu items in
      // account dropdown displayed in gNav
      $(document).on('user.loaded', function (e, data) {
        var userObjData = data;
        var userObjRes = !!userObjData === true ? userObjData : null;

        if (!!userObjRes && typeof userObjRes.signed_in !== 'undefined') {
          var $accountMenuToggle = $('.js-account-check') || $();

          if ($accountMenuToggle.length > 0) {
            $accountMenuToggle.toggleClass('hidden');
          }
          $('.js-site-header-utility-signed-in').removeClass('hidden');
          $('.js-site-header-utility-not-signed-in').addClass('hidden');
        }
      });

      $(document).on('search:open', function () {
        _closeGnav();
      });
    },
    accountMenuList: function (context) {
      // Build account menu items to be displayed in gnav
      var firstName = site.userInfoCookie.getValue('first_name');
      var signedIn = site.userInfoCookie.getValue('signed_in');
      var loyaltyMember = site.userInfoCookie.getValue('is_loyalty_member');
      var loyaltyLevelName = site.userInfoCookie.getValue('loyalty_level_name');
      var loyaltyPts = site.userInfoCookie.getValue('current_available');
      var profileNoNameTxt = '';
      var $greetingsBlock = $('.js-site-header-formatter__greetings-text', context);
      var $loyaltyPoints = $('.js-site-header-formatter__home-gnav-loyalty-pts', context);
      var $nonLoyaltyText = $('.js-site-header-formatter__loyalty-block', context);
      var text = '';
      var showFullName = Drupal.settings.common.show_full_name;
      var loyaltyPtsTxt = $loyaltyPoints.eq(0).text();

      /* GSR2-2790 - As per this bug Brazil using the full name, so in PG we overwrite the
      full name cookie as first name and to remove the '+' we replace it with space. */

      if (showFullName) {
        firstName = (firstName || '').replace(/\+/gu, ' ');
      }

      text = ' ' + firstName;
      if (signedIn === '1' && firstName === '') {
        profileNoNameTxt = $('.js-site-header-formatter__no-name-text', context).text();
        text = ' ' + profileNoNameTxt;
      }
      $greetingsBlock.append(text);

      // Validate the user is logged in the website and user is a loyalty member
      if (!_.isUndefined(signedIn) && !_.isNull(signedIn) && signedIn === '1' && loyaltyMember === '1') {
        // Hide Join Loyalty menu to the loyalty member
        $nonLoyaltyText.addClass('hidden');
        // Show the Loyalty points with current loyalty to the loyalty member
        $loyaltyPoints.removeClass('hidden');
        // Thousands separator with commas
        loyaltyPts = loyaltyPts ? loyaltyPts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
        $loyaltyPoints.html(loyaltyPts + ' ' + loyaltyPtsTxt + ' ' + loyaltyLevelName.toUpperCase());
      }
    },
    close: function (keepActive) {
      $html.toggleClass('active-gnav', !!keepActive);
      $gnavMainSectionWrapper.attr('aria-expanded', !!keepActive);

      // Reset Header Nav Section & Section Formatter elements state.
      if (isDesktop) {
        $gnavMainSection.attr('aria-expanded', 'false');
        $allSectionGroups.attr('aria-expanded', 'false');
      }
      $gnavMainSectionContent.attr('aria-hidden', 'true');
      $allSectionGroupContent.attr('aria-hidden', 'true');

      // Reset Links state.
      $gnavLinks.prop('checked', false).change();
      $gnavLinks.siblings('.gnav-links__content').attr('aria-expanded', isDesktop);

      if (!keepActive) {
        $mainSectionTriggerReset.prop('checked', true);
        $gnavMainSectionWrapper.off('keydown.restrictNavigation');
        $gnavMainSectionContent.off('keydown.restrictNavigation');
      }

      $gnavHamburgerInput.prop('checked', !!keepActive);

      $gnavHamburgerInput.off('change').on('change', function () {
        $(this).prop('checked', !!keepActive);
      });
    }
  };

  return behavior;
})(jQuery);
