(function ($) {
  Drupal.behaviors.headerMenuSearch = {
    attach: function (context) {
      var that = this;
      const landscapeUp = 1024;

      that.$trigger = $('.js-nav-category-search-trigger');

      if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr22_refresh) {
        var timeout;

        that.$trigger.off('click').on('click', function (e) {
          e.preventDefault();
          $(document).trigger('search:toggle');
        });

        that.$trigger.off('mouseover').on('mouseover', function (e) {
          if (window.innerWidth >= landscapeUp) {
            e.preventDefault();

            // Delay the opening of the Search menu in Gnav.
            timeout = setTimeout(function () {
              $(document).trigger('search:open');
            }, 200);
          }
        });

        that.$trigger.off('touchstart').on('touchstart', function (e) {
          e.preventDefault();
          $(document).trigger('search:toggle');
        });

      } else {
        that.$trigger.off('click').on('click', function (e) {
          e.preventDefault();
          $(document).trigger('search:toggle');
        });
      }
    }
  };
})(jQuery);
