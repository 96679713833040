(function ($) {
  Drupal.behaviors.siteSearch = {
    open: function () {
      this.$content.addClass(this.activeClass);
      this.$content.closest('.js-nav-header-container').addClass('search-is-active');
      this.$searchForm.css('height', window.innerHeight).addClass(this.activeClass);
      this.$closeBtns.addClass(this.activeClass);
      this.isActive = false;
      this.$micSearchIcon.addClass('hidden');
      Drupal.behaviors.siteHeaderFormatterV1?.close(false);
      this.tempEvents();
    },
    close: function () {
      this.$content.removeClass(this.activeClass);
      this.$content.closest('.js-nav-header-container').removeClass('search-is-active');
      this.$searchForm.css('height', 0).removeClass(this.activeClass);
      this.$closeBtns.removeClass(this.activeClass);
      this.$termInput.val('');
      this.isActive = true;
      this.$micSearchIcon.removeClass('hidden');
      this.tempEvents();
    },
    toggle: function() {
      if (this.isActive) {
        this.open();
      } else {
        this.close();
      }
    },
    tempEvents: function() {
      var that = this;

      if (!this.isActive) {
        $(document).off('click.search').on('click.search', function(e) {
          const $target = $(e.target);
          const outsideInput = !$target.hasClass('js-predictive-search-form-fields') && $target.closest('.js-predictive-search-form-fields').length < 1;
          const outsideWrapper = !$target.hasClass('js-esearch-nav__suggestions-wrapper') && $target.closest('.js-esearch-nav__suggestions-wrapper').length < 1;
          const outsideGnav = !$target.hasClass('js-site-header-formatter__sections') && $target.closest('.js-site-header-formatter__sections').length < 1;
          const shouldClose = outsideInput && outsideWrapper && outsideGnav;

          shouldClose && that.close();
        });
      } else {
        $(document).removeClass('active-search-preprocess').off('click.search');
      }
    },
    initEvents: function () {
      var that = this;

      $(document).on('search:open', $.proxy(that.open, this));
      $(document).on('search:close', $.proxy(that.close, this));
      $(document).on('search:toggle',$.proxy(that.toggle, this));
    },
    attach: function (context, settings) {
      var that = this;

      if (that.isAttached) {
        return null;
      }
      that.isActive = true;
      that.isAttached = true;
      that.$content = $('.js-end-esearch-nav');
      that.$closeBtns = $('.js-site-search-close');
      that.$searchForm = $('.js-end-esearch-animation');
      that.$termInput = $('.js-predictive-search-term');
      that.$navMenu = $('.js-nav-menu');
      that.activeClass = 'active';
      that.$micSearchIcon = $('.js-voice-search'); // Mic Search Icon from La Mer Voice Search module

      that.$closeBtns.on('click', function (e) {
        e.preventDefault();
        that.close();
      });

      that.$termInput.on('focus', function (e) {
        that.open();
      });

      $(window).on(
        'scroll',
        _.throttle(function () {
          if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr22_refresh) {
            return;
          }
          if ($(window).scrollTop() < that.$navMenu.height()) {
            that.$content.css('top', 'inherit');
          } else {
            that.$content.css('top', that.$navMenu.height());
          }
        }, 100)
      );

      that.initEvents();
    }
  };
})(jQuery);
