var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.productShadePickerV1 = {
    attach: function (context, settings) {
      var $products = $('.js-product:not(.js-shade-picker-processed)', context);
      $products.each(function (index, product) {
        var $product = $(this);
        var $shadePicker = $('.js-product-shade-picker', $product);
        var $shadePickerShades = $('.js-product-shade-picker__shades', $product);
        var $shade = $('.js-product-shade', $shadePicker);
        var $shadeName = $('.js-shadename', $product);
        var $productImage = $('.js-product-image--default', $product);
        var activeIndex;

        if ($shadePicker.length < 1 || $product.hasClass('js-shade-picker-processed')) {
          // Continue when the product doesn't have shade grid.
          return true;
        }
        // Attach is called multiple time for quickshop init
        // we only need to run the code once per element.
        $product.addClass('js-shade-picker-processed');
        activeIndex = $shade.filter('.active').index();
        if ($shadePicker.length) {
          $product.trigger('product.skuSelect', $shadePicker.data('sku-base-id'));
          $shade.once().click(function (event) {
            event.preventDefault();
            var skuBaseId = $(this).data('sku-base-id');

            $product.trigger('product.skuSelect', skuBaseId);
          });
        }
        if ($product.hasClass('product-brief--show-shade-size')) {
          if ($product.parents('.product-grid--small-cols-2').length > 0 && $shade.length > 3 && site.client.isMobile) {
            $shadePickerShades.not('.slick-initialized').slick({
              initialSlide: activeIndex !== -1 ? activeIndex : 0,
              slidesToShow: 3.2,
              slidesToScroll: 1,
              infinite: false,
              arrows: true
            });
          } else if ($shade.length > 7) {
            $shadePickerShades.not('.slick-initialized').slick({
              initialSlide: activeIndex !== -1 ? activeIndex : 0,
              slidesToShow: 7.2,
              slidesToScroll: 1,
              infinite: false,
              arrows: true,
              responsive: [
                {
                  breakpoint: 1180,
                  settings: {
                    slidesToShow: 5.5
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 7.2
                  }
                }
              ]
            });
          }
          $shade.hover(function () {
            var skuBaseId = $(this).data('sku-base-id');
            var skuData = prodcat.data.getSku(skuBaseId);
            var skuShadeDesc = skuData.SHADE_DESCRIPTION !== null
              ? skuData.SHADE_DESCRIPTION
              : skuData.SHADENAME;

            $shadeName.html(skuShadeDesc);
            $productImage.attr('src', skuData.IMAGE_MEDIUM[0]);
          }, function () {
            var skuBaseId = $('.js-product-shade.active', $shadePicker).data('sku-base-id');
            var skuData = prodcat.data.getSku(skuBaseId);
            var skuShadeDesc = skuData.SHADE_DESCRIPTION !== null
              ? skuData.SHADE_DESCRIPTION
              : skuData.SHADENAME;

            $shadeName.html(skuShadeDesc);
            $productImage.attr('src', skuData.IMAGE_MEDIUM[0]);
          });
        }
      });
    }
  };

  $(document).on('product.quickshop.launch.overlay product.quickshop.launch.inline product.size.shade.init', function (event, newContext) {
    Drupal.behaviors.productShadePickerV1.attach(newContext, Drupal.settings);
  });

  $(document).on('product.skuSelect product.shadePicker', '.js-product', function (event, skuBaseId) {
    if (!$('.js-product-shade-picker', this).length) {
      return;
    }
    if ($('.js-product-shade', this).length && skuBaseId !== undefined) {
      $('.js-product-shade', this).removeClass('active');
      $('.js-product-shade[data-sku-base-id="' + skuBaseId + '"]', this).addClass('active');
    }
    $('select.js-sku-menu', this).val(skuBaseId);
    var skuData = prodcat.data.getSku(skuBaseId);

    if (skuData) {
      if (skuData.SHADENAME && skuData.SHADE_DESCRIPTION) {
        $('.js-shadename', this).html(skuData.SHADE_DESCRIPTION);
      } else if (skuData.SHADENAME) {
        $('.js-shadename', this).html(skuData.SHADENAME);
      }
      if ($(event.target).hasClass('product-brief--show-shade-size') && skuData.IMAGE_MEDIUM) {
        $('.js-product-image--default', this).attr('src', skuData.IMAGE_MEDIUM[0]);
      }
      site.showEngraving(skuData);
    }
  });
})(jQuery);
